<template>
    <fragment>
        <a :title="$t('button.title.addItem')"
           v-if="$global.hasPermission('additionalservicesstore') && allowCreate > 0"
           v-b-tooltip.hover
           @click="handleAddAdditionalServiceClick"
           class="mr-1">
            <i class="fe fe-plus"></i>
        </a>
        <a :title="$t('button.title.editItem')"
           v-if="$global.hasPermission('additionalservicesupdate') && hasId && allowUpdate > 0"
           @click="handleEditAdditionalServiceClick"
           v-b-tooltip.hover>
            <i class="fe fe-edit"></i>
        </a>
        <b-modal v-model="visibility" size="lg" :title="operationTitle" hide-footer>
            <div class="additional-service-operation">
                <form @submit.prevent="handleSubmit" autocomplete="off">
                    <b-row>
                        <b-col cols="12">
                            <div v-show="editLoading">
                                <b-skeleton-table
                                    :rows="5"
                                    :columns="2"
                                    :table-props="{ bordered: true, striped: true }"
                                ></b-skeleton-table>
                            </div>

                            <div v-show="!editLoading">
                                <b-row>
                                    <b-col cols="12">
                                        <b-row>
                                            <b-col lg="6" md="6" sm="12">
                                            <b-form-group
                                                :label="$t('input.title')+' *'"
                                                label-for="title"
                                                :invalid-feedback="formErrors.first('title')"
                                            >
                                                <b-form-input
                                                    id="title"
                                                    v-model="formFields.title"
                                                    type="text"
                                                    :state="((formErrors.has('title') ? false : null))"
                                                    ref="title"
                                                    @focus="$event.target.select()"
                                                ></b-form-input>
                                            </b-form-group>
                                            </b-col>
                                            <b-col lg="6" md="6" sm="12">
                                                <b-form-group
                                                    :label="$t('input.type')+' *'"
                                                    label-for="additional_service_type_id"
                                                    :invalid-feedback="formErrors.first('additional_service_type_id')"
                                                >
                                                    <treeselect
                                                        :multiple="false"
                                                        :options="dropdowns.additional_service_types"
                                                        placeholder=""
                                                        v-model="formFields.additional_service_type_id"
                                                        :class="[{'invalid is-invalid': (formErrors.has('additional_service_type_id'))}]"
                                                    />
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                            <b-col sm="12">
                                                <b-form-group
                                                    :label="$t('input.description')"
                                                    label-for="description"
                                                    :invalid-feedback="formErrors.first('description')"
                                                >
                                                    <b-textarea
                                                        id="description"
                                                        v-model="formFields.description"
                                                        type="text"
                                                        :state="((formErrors.has('description') ? false : null))"
                                                        ref="description"
                                                        @focus="$event.target.select()"
                                                    ></b-textarea>
                                                </b-form-group>
                                            </b-col><!--/b-col-->
                                        </b-row><!--/b-row-->
                                    </b-col><!--/b-col-->
                                </b-row><!--/b-row-->
                            </div>

                            <div class="quick-modal-footer pt-1">
                                <b-button
                                    size="sm"
                                    type="submit"
                                    variant="primary"
                                    :disabled="global.pendingRequests > 0"
                                    v-b-tooltip.hover :title="$t('button.title.save')"
                                >
                                    <clip-loader style="display: inline" :loading="true" color="#fff"
                                                 size="12px"
                                                 v-if="global.pendingRequests > 0"></clip-loader>
                                    <i class="fa fa-save mr-1"></i>
                                    {{$t('button.save')}}
                                </b-button>
                                <b-button variant="warning" class="ml-3"
                                          size="sm" @click="handleOperationClose"
                                          v-b-tooltip.hover :title="$t('button.title.cancel')">
                                    <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                </b-button>
                            </div><!--/.quick-modal-footer-->
                        </b-col><!--/b-col-->
                    </b-row><!--/b-col-->
                </form>
            </div><!-- /.location-operation -->
        </b-modal>
    </fragment>
</template>
<script>
    import { Fragment } from 'vue-fragment'
    import Error from "./../../util/Error"
    import {request} from "../../util/Request"
    import {mapState} from "vuex"
    import Treeselect from '@riophae/vue-treeselect'

    const FORM_STATE = {
        title: null,
        description: null,
        additional_service_type_id: null,
        additional_service_type: [],
        _method: 'post',
    };

    export default {
        props: ['allowCreate', 'allowUpdate', 'id', 'guid', 'afterCreate', 'afterUpdate', 'afterCancel'],
        components: {
            Fragment,
            Treeselect
        },
        data() {
            return {
                formErrors: new Error({}),
                formFields: {...FORM_STATE},
                visibility: false,
                editLoading: false,
                operationTitle: this.$t('title.addAdditionalService'),
                dropdowns: {
                    additional_service_types: [],
                }
            }
        },
        mounted() {
            this.getAdditionalServiceTypes();
        },
        methods: {
            async handleSubmit() {
                this.formErrors = new Error({})
                try {
                    const response = await request({
                        url: this.formFields.id ? 'additional/services/update' : 'additional/services/create',
                        method: 'post',
                        data: this.formFields,
                    })

                    if (this.formFields.id) {
                        this.handleAfterAdditionalServiceUpdate(this.formFields, this.formFields.id);
                    } else {
                        this.handleAfterAdditionalServiceCreate(response.data);
                    }

                    this.handleOperationClose()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            async handleEditClick(id) {
                try {
                    this.editLoading = true
                    const response = await request({
                        method: 'get',
                        url: `/additional/services/detail/${id}`,
                    })
                    const {data} = response
                    this.formFields = {...data}
                } catch (e) {
                    this.itemEditFails()
                    this.formFields = {...FORM_STATE}
                } finally {
                    this.editLoading = false
                }
            },
            async getAdditionalServiceTypes() {
                try {
                    const response = await request({
                        url: '/dropdowns/additional/service/types',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.additional_service_types = data

                } catch (e) {
                    this.dropdowns.additional_service_types = []
                }
            },
            handleAddAdditionalServiceClick() {
                this.operationTitle = this.$t('title.addAdditionalService');
                this.visibility = true
            },
            handleEditAdditionalServiceClick() {
                this.operationTitle = this.$t('title.editAdditionalService');
                this.visibility = true
                this.handleEditClick(this.id)
            },
            handleOperationClose() {
                this.formFields = {...FORM_STATE}
                this.visibility = false
                this.handleAfterOperationCancel()
            },
            handleAfterOperationCancel() {
                if (this.afterCancel) {
                    this.afterCancel()
                }
            },
            handleAfterAdditionalServiceCreate(inputs) {
                if (this.afterCreate) {
                    this.afterCreate(inputs)
                }
            },
            handleAfterAdditionalServiceUpdate(inputs) {
                if (this.afterUpdate) {
                    this.afterUpdate(inputs)
                }
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
            hasId() {
                return (this.id)
            },
        }
    }
</script>
