import Error from '../../util/Error'

const SUPPLIER_ADDITIONAL_LOCATION_STATE = {
    token: null,
    id: null,
    price: 0,
    currency: null,
    error: false
};

export default {
    data() {
        return {
            supplier_additional_service: {...SUPPLIER_ADDITIONAL_LOCATION_STATE},
            supplier_additional_services: [],
            dropdowns: {
                additional_services: [],
            }
        }
    },
    methods: {
        handleAddUpdateSupplierAdditionalServiceClick() {
            this.supplier_additional_service.error = false;
            this.formErrors = new Error({})
            let errors = {};

            if (!this.supplier_additional_service.id) {
                this.supplier_additional_service.error = true;
                errors.additional_service_id = [this.$t('validation.required')];
            }

            if (!this.supplier_additional_service.price || this.supplier_additional_service.price <= 0) {
                this.supplier_additional_service.error = true;
                errors.price = [this.$t('validation.required')];
            }

            if (!this.supplier_additional_service.currency) {
                this.supplier_additional_service.error = true;
                errors.currency = [this.$t('validation.required')];
            }

            _.map(this.supplier_additional_services, (service) => {
                if(service.token !== this.supplier_additional_service.token) {
                    if(service.id === this.supplier_additional_service.id) {
                        errors.additional_service_id = [this.$t('validation.duplicate')];
                        this.supplier_additional_service.error = true;
                        this.formErrors = new Error(errors);
                        return false;
                    }
                }
            });

            this.formErrors = new Error(errors)
            if(this.supplier_additional_service.error) return false;

            const entity = this.supplier_additional_service;
            let index = -1;
            if (entity.token)
                index = _.findIndex(this.supplier_additional_services, {token: entity.token});

            if (this.supplier_additional_services[index] !== undefined) {
                this.supplier_additional_services[index] = entity;
            } else {
                entity.token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
                this.supplier_additional_services.push(entity)
            }

            this.supplier_additional_service = {...SUPPLIER_ADDITIONAL_LOCATION_STATE, token: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}
        },
        handleDeleteSupplierAdditionalServiceClick(token) {
            const index = _.findIndex(this.supplier_additional_services, {token: token})
            if (this.supplier_additional_services[index] !== undefined) {
                this.supplier_additional_services.splice(index, 1);
            }
        },
        handleEditSupplierAdditionalServiceClick(token) {
            const index = _.findIndex(this.supplier_additional_services, {token: token})
            if (this.supplier_additional_services[index] !== undefined) {
                this.supplier_additional_service = {...this.supplier_additional_services[index]};
            }
        },
        resetSupplierAdditionalService() {
            this.supplier_additional_service = {...SUPPLIER_ADDITIONAL_LOCATION_STATE}
        }
    }
}
